import { createModule } from 'redux-modux'

const initialState = {
  // adminId: '600597fa85c715552c00a83d',
  adminId: window.localStorage.getItem('pp-chat-id'),
  adminName: window.localStorage.getItem('pp-chat-name'),
  adminRole: window.localStorage.getItem('pp-chat-role')
}

const setAdmin = (state, action) => {
  return {
    ...state,
    adminId: action.id,
    adminName: action.name,
    adminRole: action.role
  }
}

const handlers = {
  setAdmin
}

export default createModule({ moduleName: 'data', initialState, handlers })
