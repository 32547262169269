import { Route, Switch } from 'react-router-dom'
import React, { lazy } from 'react'

const Chat = lazy(() => import('./pages/Chat'))
const Login = lazy(() => import('./pages/Login'))
const ChatHistory = lazy(() => import('./pages/ChatHistory'))

export default (
  <Switch>
    <Route exact path='/' component={Login} />
    <Route exact path='/chat' component={Chat} />
    <Route exact path='/chat-history' component={ChatHistory} />
    <Route component={() => <div>404 Page not found</div>} />
  </Switch>
)
